

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import MaterialBox from "../../components/MaterialBox";
import Footer from "./components/Footer";

function PageLayout({ background, children, disableFooter, height, minHeight, addPadding }) {

    return (
        <>
            <MaterialBox
                width="100%"
                height={height ? height : '85vh'}
                minHeight={minHeight}
                bgColor={background}
                sx={addPadding ? { pl: 5, pr: 5, pt: 2, pb: 5, overflowX: "hidden", } : { overflowX: "hidden" }}
            >
                {children}
            </MaterialBox >
            {
                !disableFooter && (
                    <Footer />
                )
            }
        </>
    );
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
    background: PropTypes.oneOf(["white", "light", "default"]),
    children: PropTypes.node.isRequired,
    disableFooter: PropTypes.bool,
    height: PropTypes.string,
    minHeight: PropTypes.string,
    addPadding: PropTypes.bool,
};

export default PageLayout;
