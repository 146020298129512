import ComingSoon from "./pages/comingsoon";

const routes = [
    {
        name: "Authentication",
        key: "authentication",
        collapse: [
            {
                name: "Coming Soon",
                key: "coming-soon",
                route: "/",
                component: <ComingSoon />,
            },
        ],
    },
    
]
export default routes;