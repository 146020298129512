import React from 'react'
import { Fragment } from 'react'
import MaterialBox from '../../components/MaterialBox'
import MaterialTypography from '../../components/MaterialTypography';
import PageLayout from '../../layoutcontainers/PageLayout';
import Grid from "@mui/material/Grid2";
import UnderProcess from '../../assest/images/coming-soon.jpg'
import logo from '../../assest/images/logo.png'
import bgImage from '../../assest/images/bgImageSpa.jpg'
import { Stack } from '@mui/material';

// Images

const ComingSoon = () => {

    return (
        <PageLayout height='95vh'>
            <MaterialBox bgColor="white">
                <MaterialBox
                    minHeight="50vh"
                    height='100%'
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                            linearGradient(gradients.dark.main, gradients.dark.state),
                    }}
                />
                <MaterialBox
                    variant="gradient"
                    bgColor="white"
                    position="relative"
                    borderRadius="xl"
                    mx={{ xs: 2, xl: 3, xxl: 16 }}
                    mt={-35}
                    px={3}
                    sx={{ overflow: "hidden" }}
                >
                    <MaterialBox
                        component="img"
                        src={bgImage}
                        alt="pattern-lines"
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height='100%'
                    // opacity={0.8}
                    />
                    <Grid container display="flex" justifyContent="center" alignItems="center" p={6}>

                        <Grid size={{ xs: 12, md: 6, lg: 6 }} ml="auto" position="relative">
                            <MaterialBox display="flex" justifyContent="center" alignItems="center" >
                                <img src={logo} alt="Book 4 Spa"
                                    style={{
                                        width: '20%',
                                        height: 85,
                                        justifyContent: "center",
                                        textAlign: "center",
                                        alignItems: "center",
                                        borderRadius: '15px'
                                    }}
                                />
                            </MaterialBox>
                            <MaterialBox display="flex" alignItems="center" flexDirection="column" px={3} mt={2} justifyContent="center" >
                                <MaterialTypography variant="h4" textAlign="center"
                                  color='white'
                                >
                                    Your Destination for Beauty and Relaxation
                                </MaterialTypography>
                            </MaterialBox>


                            <MaterialBox p={2} display="flex" flexDirection="column" mt={10}>
                                <Fragment>
                                    <Stack spacing={2}>
                                        <MaterialBox display='flex' textAlign='left'>
                                            <MaterialTypography variant='h1' sx={{ color: "#FFC500",  textAlign: 'center', fontSize: 48 }}>
                                                Relaxation is just
                                            </MaterialTypography>
                                        </MaterialBox>
                                        <MaterialBox display='flex' textAlign='left' mt={3}>
                                            <MaterialTypography variant='h1' sx={{ color: "#FFC400",  textAlign: 'center', fontSize: 48 }}>
                                                around the corner
                                            </MaterialTypography>
                                        </MaterialBox>
                                        <MaterialBox display='flex' textAlign='left' mt={3}>
                                            <MaterialTypography variant='h1' sx={{ color: "#FFED86", textAlign: 'center', fontSize: 48 }}>
                                                Stay tuned.
                                            </MaterialTypography>
                                        </MaterialBox>
                                    </Stack>
                                </Fragment>
                            </MaterialBox>
                        </Grid>

                        <Grid size={{ xs: 12, md: 6 }} position="absolute" right="55%" ml={-32} width="75%">
                            <MaterialBox display='flex' justifyContent='center' alignItems='center'
                                opacity={1} >
                                <MaterialBox
                                    component="img"
                                    src={UnderProcess}
                                    alt="macbook"
                                    width="100%"
                                    borderRadius='15px'
                                    display={{ xs: "none", md: "block" }}
                                />
                            </MaterialBox>
                        </Grid>
                    </Grid>
                </MaterialBox>
            </MaterialBox>
        </PageLayout>
    )
}

export default ComingSoon