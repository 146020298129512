import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import logo from '../../../../assest/images/keel-logo.png'

function Footer() {
    return (
        <MaterialBox sx={{ textAlign: "right" }}>
            <MaterialTypography textAlign="center" variant="body2" color="dark" mr={1}>
                Powered by <a href="https://keelis.com/" style={{ textDecoration: "none", color: '#000', fontSize: '13px' }}>KEELIS</a> <img style={{ width: 18, height: 12 }} src={logo} alt="Keel info solution"></img>
            </MaterialTypography>
        </MaterialBox >
    );
}

export default Footer;
